.number-badge {
  --border-width: 1px;

  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  min-width: 28px;
  padding: 2px calc(var(--spacing-md) - var(--border-width));
  align-items: center;
  border: solid var(--border-width) var(--color-u200);
  background-color: var(--color-u50);
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  white-space: nowrap;

  &.rounded {
    height: 28px;
    padding: 2px 5px;
  }

  // double to make more specific
  &.error.error {
    color: var(--color-n300);
  }

  &.INFO {
    color: var(--color-u700);
    background-color: var(--color-u50);
    border-color: var(--color-u200);
  }

  &.SUCCESS {
    color: var(--color-g800);
    background-color: var(--color-s050);
    border-color: var(--color-g400);
  }

  &.ERROR {
    color: var(--color-e700);
    background-color: var(--color-r050);
    border-color: var(--color-r300);
  }

  &.MISSING_DOCUMENT_CATEGORY {
    color: var(--color-e500);
  }

  &.MISSING_DOCUMENT_CATEGORY_ITEM {
    color: var(--color-u300);
  }

  .dot {
    height: 2px;
    width: 2px;
    margin-right: var(--spacing-xs);
    margin-left: var(--spacing-xs);

    border-radius: 9999px;
    position: relative;
    background-color: var(--color-u700);
    color: var(--color-u700);
  }

  /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
  .dot-flashing {
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: calc(-1 * var(--spacing-xs));
    width: 2px;
    height: 2px;
    border-radius: 9999px;
    background-color: var(--color-u700);
    color: var(--color-u700);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: var(--spacing-xs);
    width: 2px;
    height: 2px;
    border-radius: 9999px;
    background-color: var(--color-u700);
    color: var(--color-u700);
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: var(--color-u700);
    }
    50%,
    100% {
      background-color: #eaecf0;
    }
  }
}
